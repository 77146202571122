import React, { useState } from "react";
import { PageProps } from "gatsby";
import { useMediaQuery } from "@material-ui/core";
import { MainComponentM2ApvRetirement, SEO } from "@components";
import { useAuth } from "@hooks";

const ChooseApv: React.FC = () => {
  useAuth({ redirectIfNotLogged: true });
  const [page, setPage] = useState<number>(1);

  const isMobile = useMediaQuery("(max-width: 1300px)");

  const [mission, setMission] = useState(2);
  return (
    <>
      <SEO />
      {mission === 1 ? (
        <></>
      ) : mission === 2 ? (
        <MainComponentM2ApvRetirement />
      ) : (
        mission === 3 && <></>

      )}
    </>
  );
};

export default ChooseApv;
